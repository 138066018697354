exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-checkout-completed-order-id-js": () => import("./../../../src/pages/checkout-completed/[order_id].js" /* webpackChunkName: "component---src-pages-checkout-completed-order-id-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-equipment-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/equipment/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-equipment-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-hyr-ljud-till-studentflak-goteborg-js": () => import("./../../../src/pages/hyr-ljud-till-studentflak-goteborg.js" /* webpackChunkName: "component---src-pages-hyr-ljud-till-studentflak-goteborg-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-utkorning-och-montering-js": () => import("./../../../src/pages/utkorning-och-montering.js" /* webpackChunkName: "component---src-pages-utkorning-och-montering-js" */),
  "component---src-pages-villkor-js": () => import("./../../../src/pages/villkor.js" /* webpackChunkName: "component---src-pages-villkor-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/CategoryList.js" /* webpackChunkName: "component---src-templates-category-list-js" */)
}

