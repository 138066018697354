import React from "react";
import "@popperjs/core/dist/umd/popper.min.js";
import { Script } from "gatsby"
import MiniCart from './src/components/minicart'

import wrapWithProvider from "./wrapWithProvider"
export const wrapRootElement = wrapWithProvider

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <MiniCart />
      <Script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.2/js/bootstrap.min.js" />
    </>
  )
}