import React from "react"
import { CartProvider } from "react-use-cart"
import GlobalContextProvider from "./src/GlobalContext"

// eslint-disable-next-line react/display-name,react/prop-types
const wrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <GlobalContextProvider>
      <CartProvider>
        {element}
      </CartProvider>
    </GlobalContextProvider>
  )
}

export default wrapWithProvider