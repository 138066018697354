import React from "react"
import { useCart } from "react-use-cart";
import "./quantity.scss"

const Quantity = ({ item }) => {
    const {
      updateItemQuantity,
      } = useCart();
  return (
    <div className="quantity-wrap d-inline-flex align-items-center">
      <button
        onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
        className="btn btn-light"
        aria-label="Minska antal av produkten"
      >
        <i className="bi bi-dash-circle-fill" />
      </button>
      <div>{item.quantity}</div>
      <button
        onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
        className="btn btn-light"
        aria-label="Öka antal av produkten"
      >
        <i className="bi bi-plus-circle-fill" />
      </button>
    </div>
  )
}

export default Quantity
