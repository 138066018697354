import calculateDiscount from "./calculateDiscount"

const calculateGrandTotal = (cartTotal, items, customerHasDiscount, discout_factor) => {
  let grandTotal
  if (customerHasDiscount) {
    grandTotal = cartTotal - calculateDiscount(items, discout_factor)
  } else {
    grandTotal = cartTotal
  }
  return grandTotal 
}

export default calculateGrandTotal