// Similar to formatViewProductToGoogleStructure, but includes a quantity

const formatProductToGoogleStructure = (product, customerHasDiscount, DISCOUNT_FACTOR) => {
  return {
    item_id: product.id,
    item_name: product.name,
    quantity: product.quantity,
    price: Number(((product.discountAvailable && customerHasDiscount) ? (product.price * product.quantity) * DISCOUNT_FACTOR : product.price)),
    ...((product.discountAvailable && customerHasDiscount) && { discount: Number((product.price * product.quantity) - ((product.price * product.quantity) * DISCOUNT_FACTOR))}),
  }
}

export default formatProductToGoogleStructure;