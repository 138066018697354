import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import { useCart } from "react-use-cart";
import Quantity from "/src/components/cart/components/quantity"
import { GatsbyImage } from "gatsby-plugin-image"
import { NumericFormat } from 'react-number-format';
import { GlobalContext } from "/src/GlobalContext"
import { DISCOUNT_FACTOR } from "/src/constants"
import "./minicart.scss"
import calculateGrandTotal from "../cart/functions/calculateGrandTotal";
import mapCartToGoogleStructure from "../../shared/mapCartToGoogleStructure";

const MiniCart = () => {
  const {
    isEmpty,
    items,
    cartTotal
  } = useCart();

  const [isClient, setIsClient] = useState(false)
  const { customerHasDiscount } = useContext(GlobalContext)

  useEffect(() => {
    setIsClient(true);
  }, []);

  const navigateToCheckout = (e) => {
    e.preventDefault()
    if(window?.dataLayer) {
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
          currency: "SEK",
          ...(customerHasDiscount && { cupon: "studentrabatt" }),
          items: mapCartToGoogleStructure(items, customerHasDiscount, DISCOUNT_FACTOR),
          value: calculateGrandTotal(cartTotal, items, customerHasDiscount, DISCOUNT_FACTOR)
        }
      });
    } 
    navigate("/checkout")
  }

  return (
    <>
      <div className="offcanvas offcanvas-end custom-offcanvas" data-bs-scroll="true" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">Utrustning i din bokning</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          {isClient && isEmpty &&
            <p>Din bokning innehåller ingen utrustning. Lägg till utrustning i din bokning och kom sedan tillbaka hit.</p>
          }
          <ul className="list-group mb-3">
            {isClient && items.map((item) => (
              <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center lh-sm">
                <div className="product-image"><GatsbyImage alt="" image={item.image} /></div>
                <div>
                  <h6 className="my-0">{item.name}</h6>
                  <small className="text-body-secondary">{item.description}</small>
                </div>
                  <Quantity item={item} />
                  <NumericFormat className="text-body-secondary" displayType="text" value={item.price * item.quantity} thousandsGroupStyle="thousand" thousandSeparator=" " suffix={' kr'} />

              </li>
            ))}
          </ul>
        </div>
        <div className="p-3">
            <hr />
            <div className="d-flex justify-content-between align-items-center text-success">
              <h6 className="my-0">Totalsumma för bokning</h6>

              {isClient && <NumericFormat className="fw-bold" displayType="text" value={calculateGrandTotal(cartTotal, items, customerHasDiscount, DISCOUNT_FACTOR)} thousandsGroupStyle="thousand" thousandSeparator=" " suffix={' kr'} />}
              
            </div>
            <button data-bs-dismiss="offcanvas" onClick={navigateToCheckout} className="btn btn-primary w-100 mt-3">Gå till din bokning</button>
          </div>
      </div>
    </>
  )
}

export default MiniCart