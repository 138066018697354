// This functions returnes the discount amount of a cart in its actual positive value, i.e if you have 1000 SEK and you get 20% discount, this function will return 200 SEK.
// items = array
// discount amount = fractional number
// return = number

const calculateDiscount = (cart, discountFactor) => {
  let discountAmount = Number();
  cart.forEach(cartItem => {
    if(cartItem.discountAvailable) {
      discountAmount += cartItem.itemTotal
    }
  });
  return discountAmount - (discountAmount * discountFactor)
}

export default calculateDiscount;